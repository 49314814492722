import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
// import Payment from '../Pages/PageElements/PaymentElements/Payment';
import CompulsoryProfileInfo from './ModalElements/CompulsoryProfileInfo';
import ValidateEmail from './ModalElements/ValidateEmail';
import SquarePayment from '../Pages/PageElements/PaymentElements/SquarePayment';
// eslint-disable-next-line
import Spinner from 'react-bootstrap/Spinner';

const BuySeasonPassModal = () => {
    const { API_LINK, showBuyPassModal, setShowBuyPassModal, setShowBuyPassEventID, showBuyPassEventID, usersID,
        setEvents, events, isLiveEvent, dob, setDob, gender, setGender, firstname, setFirstname, lastname, setLastname,
        phoneNumber, setPhoneNumber, deliveryAddress, setDeliveryAddress, postcode, setPostcode, infoValid, setInfoValid,
        getCompetitorsInfo, emailValidated, userInfoValid
    } = useContext(Context);

    // eslint-disable-next-line
    const [price, setPrice] = useState(0);
    const [seasonNumber, setSeasonNumber] = useState(0);
    const [eventName, setEventName] = useState('');
    const [eventType, setEventType] = useState('')
    // eslint-disable-next-line
    const [processingPayment, setProcessingPayment] = useState(false);

    const handleClose = () => {
        setShowBuyPassEventID('');
        setShowBuyPassModal(false);
        getCompetitorsInfo();
        setTimeout(() => {
            setProcessingPayment(false);
        }, 2000);

    }

    useEffect(() => {
        getCompetitorsInfo();
    }, [getCompetitorsInfo])

    useEffect(() => {
        for (let i = 0; i < events?.length; i++) {
            if (events[i]._id === showBuyPassEventID) {
                if (isLiveEvent) {
                    setPrice(events[i].liveFee);
                    setEventName(`${events[i].event_name}`);
                } else {
                    setPrice(events[i].virtualFee);
                    setEventName(`${events[i].event_name}`);
                }
                setEventType(events[i].eventType);
                setSeasonNumber(events[i].season_number);
            }
        }
    }, [events, showBuyPassEventID, isLiveEvent])

    // eslint-disable-next-line
    const handleMakePayment = async () => {
        const response = await fetch(API_LINK + '/pay-for-event', {
            method: 'POST',
            body: JSON.stringify({ userID: usersID, eventID: showBuyPassEventID }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                if (data.error) {
                    console.log('There was an error: ' + data.error);
                } else {
                    setEvents(data.events);
                    handleClose();
                }
            });
        }
    }

    const updateCompulsoryInfo = async () => {
        const response = await fetch(API_LINK + '/update-compulsory-info', {
            method: 'POST',
            body: JSON.stringify({
                dob, gender, firstname, lastname, phoneNumber, deliveryAddress, postcode, userID: usersID
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                if (data.error) {
                    console.log('There was an error: ' + data.error);
                } else {
                    let user = data.user;
                    setFirstname(user?.firstname);
                    setLastname(user?.lastname);
                    setPhoneNumber(user?.phone_number);
                    setDeliveryAddress(user?.delivery_address);
                    setPostcode(user?.postcode);
                    setDob(user?.dob);
                    setGender(user?.gender);
                    let userValid = userInfoValid({
                        firstname: user?.firstname,
                        lastname: user?.lastname,
                        phoneNumber: user?.phone_number,
                        deliveryAddress: user?.delivery_address,
                        postcode: user?.postcode,
                        dob: user?.dob,
                        gender: user?.gender
                    });
                    setInfoValid(userValid);
                }
            });
        }
    }

    const handleChangeValue = (event) => {
        let type = event.target.name;
        let value = event.target.value;
        switch (type) {
            case 'firstname':
                setFirstname(value);
                break;
            case 'lastname':
                setLastname(value);
                break;
            case 'phone-number':
                setPhoneNumber(value);
                break;
            case 'delivery-address':
                setDeliveryAddress(value);
                break;
            case 'postcode':
                setPostcode(value);
                break;
            default:
                break;
        }
    }

    const handleSelectGender = async (eventGender) => {
        setGender(eventGender);
    };

    return (
        <Modal show={showBuyPassModal} onHide={handleClose} className="modal-dark">
            {!emailValidated &&
                <Modal.Header closeButton>
                    <Modal.Title>Validation Required</Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                {emailValidated ?
                    infoValid ?
                        // <Payment handleMakePayment={handleMakePayment} price={price} seasonNumber={seasonNumber} />
                        processingPayment ?
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                            :
                            <>
                                <h1 className="glow-text" style={{
                                    textAlign: 'center', marginBottom: '10px', fontFamily: 'Neon',
                                    color: '#26ffe2', fontSize: '4em', lineHeight: '0.7em'
                                }}>
                                    {eventType === 'Hybrid' && `Season #${seasonNumber}`}
                                    {(eventType === 'Live' || eventType === 'Virtual') && eventName}
                                    <br />£{price}
                                </h1>
                                <h3 className="glow-text" style={{
                                    textAlign: 'center', marginBottom: '30px', fontFamily: 'Neon',
                                    color: '#26ffe2', lineHeight: '0.7em'
                                }}>
                                    {isLiveEvent ? 'LIVE EVENT' : 'VIRTUAL EVENT'}
                                </h3>
                                <SquarePayment price={price} seasonNumber={seasonNumber} handleClose={handleClose}
                                    setProcessingPayment={setProcessingPayment} eventName={eventName} />
                            </>
                        :
                        <>
                            <p>Before you can purchase this pass we need to make we've got all the info we need.</p>
                            <CompulsoryProfileInfo dob={dob} setDob={setDob} gender={gender} firstname={firstname}
                                lastname={lastname} phoneNumber={phoneNumber} deliveryAddress={deliveryAddress} postcode={postcode}
                                handleChangeValue={handleChangeValue} handleSelectGender={handleSelectGender} />
                            <button className="green-glow-button-large" style={{ marginTop: '30px' }} id="submit"
                                onClick={updateCompulsoryInfo}>
                                <span id="button-text">
                                    SAVE INFO
                                </span>
                            </button>
                        </>
                    :
                    <ValidateEmail />
                }
            </Modal.Body>
        </Modal>
    )
}

export default BuySeasonPassModal