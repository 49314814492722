import React, { useContext } from 'react';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { Context } from '../../../../lib/Context';

const SquarePayment = (props) => {
    // eslint-disable-next-line
    const { price, seasonNumber, handleClose, setProcessingPayment, eventName } = props;
    const { API_LINK, firstname, lastname, squareCustomerID, showBuyPassEventID, isLiveEvent,
        usersID, setEvents, deliveryAddress, deliveryAddressTwo, city
    } = useContext(Context);

    const makePayment = async ({ token }) => {
        const response = await fetch(API_LINK + '/make-square-payment', {
            method: 'POST',
            body: JSON.stringify({
                token, amount: price, currency: 'GBP', squareCustomerID, eventID: showBuyPassEventID,
                isLiveEvent, userID: usersID, eventName
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setEvents(data.events);
                handleClose();
            });
        }
    }
    return (
        <>
            <PaymentForm
                applicationId={process.env.REACT_APP_SQ_APP_ID}
                cardTokenizeResponseReceived={(token, buyer) => {
                    setProcessingPayment(true);
                    makePayment({ token, buyer });
                }}
                createVerificationDetails={() => ({
                    amount: price.toString(),
                    /* collected from the buyer */
                    billingContact: {
                        addressLines: [deliveryAddress, deliveryAddressTwo],
                        familyName: lastname,
                        givenName: firstname,
                        countryCode: 'GB',
                        city: city,
                    },
                    currencyCode: 'GBP',
                    intent: 'CHARGE'
                })}
                locationId={process.env.REACT_APP_SQ_LOCATION_ID}
            >
                <CreditCard />
            </PaymentForm>
        </>
    )
}

export default SquarePayment