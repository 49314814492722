import { useContext, useState } from 'react';
import validateEmail from '../../lib/Functions/validateEmail';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Context } from '../../lib/Context';
import '../../lib/css/DarkModal.css';
import { useNavigate } from 'react-router-dom';

function AuthModal(props) {
    const { showAuth, setShowAuth } = props;
    const { API_LINK, setFirstname, setLastname, setEmail, setProfilePicture, setEvents, setType, setUsersID,
        handleCheckUserDetails
    } = useContext(Context);
    const [loginEmail, setLoginEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [securityCodeSent, setSecurityCodeSent] = useState(false);
    const [validationCode, setValidationCode] = useState('');
    const [codeValid, setCodeValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // If user has password or not (if email has been validated then password will be set).
    const [emailValidated, setEmailValidated] = useState(false);

    const navigate = useNavigate();

    const login = async () => {
        const response = await fetch(API_LINK + '/login', {
            method: 'POST',
            body: JSON.stringify({ password, email: loginEmail }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setFirstname(data.firstname);
                setEmail(data.email);
                setLastname(data.lastname);
                setProfilePicture(data.profilePicture);
                setEvents(data.events);
                setType(data.type);
                setUsersID(data.usersID);
                closeModal();
                handleCheckUserDetails({ userID: data.usersID, events: data.events });
                navigate('/events');
                // Check to see if the user is any upcoming events
                // Then display a popup to get them to enter in their weights for the event
            });
        } else {
            setErrorMessage('That doesn\'t seem to be the right password for this email.')
        }
    }

    // This builds the input data from the form inputs.
    const buildInputs = (event) => {
        const value = event.target.value;
        const type = event.target.name;
        switch (type) {
            case 'validation-code':
                setValidationCode(value);
                break;
            case 'password':
                setPassword(value);
                confirmPassword === value && confirmPassword.length > 0 ? setPasswordsMatch(true) : setPasswordsMatch(false);
                break;
            case 'confirm-password':
                setConfirmPassword(value);
                password === value && password.length > 0 ? setPasswordsMatch(true) : setPasswordsMatch(false);
                break;
            default:
                break;
        }
    }

    // Checks to see if an email is valid and if it is retrieves the information from the backend.
    const buildEmail = async (event) => {
        setValidEmail(false);
        const userInput = event.target.value.toLowerCase();
        console.log(userInput)
        const isValid = validateEmail(userInput);
        console.log(isValid);
        if (isValid) {
            const response = await fetch(API_LINK + '/check-users-email', {
                method: 'POST',
                body: JSON.stringify({ email: userInput }),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            if (response.ok) {
                response.json().then(async data => {
                    console.log('Checked email');
                    setEmailValidated(data);
                    setValidEmail(true);
                });
            }
        }
        setLoginEmail(userInput);
    }

    const generateValidationCode = async () => {
        const response = await fetch(API_LINK + '/generate-validation-code', {
            method: 'POST',
            body: JSON.stringify({ email: loginEmail }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setSecurityCodeSent(true);
            });
        }
    }

    const submitValidationCode = async () => {
        const response = await fetch(API_LINK + '/submit-validation-code', {
            method: 'POST',
            body: JSON.stringify({ validationCode, email: loginEmail }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setCodeValid(data);
                setSecurityCodeSent(true);
                if (!data) {
                    setErrorMessage('That code is not correct. Please try again!');
                } else {
                    setErrorMessage('');
                }
            });
        }
    }

    const setNewPassword = async () => {
        const response = await fetch(API_LINK + '/set-new-password', {
            method: 'POST',
            body: JSON.stringify({ password, email: loginEmail }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async () => {
                await login();
            });
        }
    }

    const closeModal = () => {
        setShowAuth(false);
        setLoginEmail('');
        setPassword('');
        setPasswordsMatch(false);
        setConfirmPassword('');
        setValidEmail(false);
        setSecurityCodeSent(false);
        setValidationCode('');
        setCodeValid(false);
        setErrorMessage('');
    }

    const forgotPassword = () => {
        setEmailValidated(false);
        setSecurityCodeSent(false);

    }

    return (
        <Modal show={showAuth} onHide={closeModal} className="modal-dark">
            <Modal.Header closeButton>
                <Modal.Title>Login or Register</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ marginBottom: '30px' }}>
                    <Form>
                        <p>Please enter the email you wish to <u>login</u> or <u>register</u> with.</p>
                        <Form.Group className="mb-3" controlId="login-email">
                            <Form.Control type="email" value={loginEmail} onInput={buildEmail} placeholder="name@example.com" />
                        </Form.Group>
                        {validEmail && !emailValidated && !securityCodeSent &&
                            <p>Please generate a code using the button below.</p>
                        }
                        {validEmail && emailValidated &&
                            <>
                                <Form.Group className="mb-3" controlId="login-password">
                                    <Form.Control type="password" name="password" value={password} onInput={buildInputs}
                                        placeholder="Password" />
                                </Form.Group>
                                <p onClick={forgotPassword} style={{
                                    color: '#0D6EFD', width: '100%', textAlign: 'center', cursor: 'pointer'
                                }}>Forgot Password?</p>
                            </>
                        }
                        {validEmail && !emailValidated && securityCodeSent && !codeValid &&
                            <>
                                <p>Please check your email and enter in the code we sent you below.</p>
                                <Form.Group className="mb-3" controlId="validation-code">
                                    <Form.Control type="tel" name="validation-code" value={validationCode}
                                        onInput={buildInputs} placeholder="Security Code" />
                                </Form.Group>
                            </>
                        }
                        {validEmail && !emailValidated && securityCodeSent && codeValid &&
                            <>
                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Control type="password" name="password" value={password}
                                        onInput={buildInputs} placeholder="Password" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="confirm-password">
                                    <Form.Control type="password" name="confirm-password" value={confirmPassword}
                                        onInput={buildInputs} placeholder="Confirm Password" />
                                </Form.Group>
                            </>
                        }
                    </Form>

                </Row>
                {errorMessage &&
                    <Alert variant="danger" style={{ width: '100%', textAlign: 'center' }}>
                        {errorMessage}
                    </Alert>
                }
            </Modal.Body>
            {validEmail &&
                <Modal.Footer>
                    {!emailValidated && !securityCodeSent &&
                        <button className="green-glow-button" onClick={generateValidationCode}>
                            Validate Email Ownership
                        </button>
                    }
                    {!emailValidated && securityCodeSent && !codeValid &&
                        <button className="green-glow-button" onClick={submitValidationCode}>
                            Submit Code
                        </button>
                    }
                    {!emailValidated && securityCodeSent && codeValid && passwordsMatch &&
                        <button className="green-glow-button" onClick={setNewPassword}>
                            Submit New Password
                        </button>
                    }
                    {!emailValidated && securityCodeSent && codeValid && !passwordsMatch &&
                        <Alert variant="success" style={{ width: '100%', textAlign: 'center' }}>
                            Please input your desired password.
                        </Alert>
                    }
                    {emailValidated &&
                        <button className="green-glow-button" onClick={login}>
                            Login
                        </button>
                    }
                </Modal.Footer>
            }
        </Modal>
    );
}

export default AuthModal;