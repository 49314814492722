import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import '../../lib/css/DarkModal.css';
import { motion as m } from 'framer-motion';
import '../../lib/css/App.css';
import Spinner from '../Pages/PageElements/Spinner'
import MapAdjudicator from '../Pages/PageElements/MapAdjudicator';

const EditAdjudicatorsModal = () => {
    const {
        API_LINK, events, showEditAdjudicatorModel, setShowEditAdjudicatorModal, setEditAdjudicatorEventID, editAdjudicatorEventID,
        setMapAdjudicatorsEventType, mapAdjudicatorsEventType
    } = useContext(Context);
    const [numberOfLanes, setNumberOfLanes] = useState(0);
    const [adjudicators, setAdjudicators] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [event, setEvent] = useState({});

    const handleClose = () => {
        setShowEditAdjudicatorModal(false);
        setTimeout(() => {
            setEditAdjudicatorEventID('');
            setMapAdjudicatorsEventType('');
            setAdjudicators([]);
            setStartDate('');
        }, "200");
    }

    useEffect(() => {
        // Get all the adjudicators for the event.
        // Get how many lanes for the event (setNumberOfLanes).
        if (editAdjudicatorEventID !== '') {
            events.forEach(event => {
                if (event._id === editAdjudicatorEventID) {
                    let day, year, month
                    if (mapAdjudicatorsEventType === 'Live') {
                        [year, month, day] = event.start_time_live.split('T')[0].split('-');
                    } else if (mapAdjudicatorsEventType === 'Virtual') {
                        [year, month, day] = event.start_time_virtual.split('T')[0].split('-');
                    }
                    setStartDate(`${day}-${month}-${year}`);
                    setEvent(event);
                }
            });
            const getAdjudicators = async () => {
                const response = await fetch(API_LINK + '/get-adjudicators-for-event', {
                    method: 'POST',
                    body: JSON.stringify({ eventID: editAdjudicatorEventID }),
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include'
                })
                if (response.ok) {
                    response.json().then(async data => {
                        setAdjudicators(data.adjudicators);
                        setNumberOfLanes(data.lanes);
                    });
                }
            }
            getAdjudicators();
        }

    }, [API_LINK, editAdjudicatorEventID, events, mapAdjudicatorsEventType])



    return (
        <Modal size="lg" show={showEditAdjudicatorModel} onHide={handleClose} className="modal-dark">
            <Modal.Header closeButton>
                <Modal.Title>
                    Adjudicators | {event.address} ({startDate})
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.75, ease: 'easeOut' }}>

                    {adjudicators?.length !== 0 ?
                        <table style={{ width: '100%', borderSpacing: '0 15px', padding: '20px' }} className="no-bg">
                            <thead>
                                <tr>
                                    <th className="no-bg">Firstname</th>
                                    <th className="no-bg">Lastname</th>
                                    <th className="no-bg">Email</th>
                                    <th className="no-bg">Lane</th>
                                    <th className="no-bg">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {adjudicators?.map((adjudicator, index) => (
                                    <tr key={'map-adjudicator-' + adjudicator._id + '-' + index} className="no-bg">
                                        <MapAdjudicator
                                            adjudicator={adjudicator} numberOfLanes={numberOfLanes}
                                            setAdjudicators={setAdjudicators} adjudicatorID={adjudicator._id}
                                        />
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                        :
                        <Spinner />
                    }
                </m.div>
            </Modal.Body>
            <Modal.Footer>
                <button className="grey-glow-button" onClick={handleClose}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditAdjudicatorsModal