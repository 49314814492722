// eslint-disable-next-line
import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Context } from '../../../../lib/Context';

const SwitchEventAdjudicator = (props) => {
    const { event, competitorObject } = props;
    const { API_LINK } = useContext(Context);
    const [selectedAdjudicator, setSelectedAdjudicator] = useState({});
    const [adjudicatorsDropdownList, setAdjudicatorsDropdownList] = useState([]);

    useEffect(() => {
        // Find the selected adjudicator based on userID
        const selectedAdjudicator = event.adjudicators.find(
            (adjudicator) => adjudicator.userID === competitorObject.adjudicator.userID
        );

        if (selectedAdjudicator) {
            // Set the selected adjudicator
            setSelectedAdjudicator(selectedAdjudicator);

            // Filter adjudicators who share the same lane
            const laneAdjudicators = event.adjudicators.filter(
                (adjudicator) => adjudicator.lane === competitorObject.adjudicator.lane
            );

            // Set the list of lane adjudicators
            setAdjudicatorsDropdownList(laneAdjudicators);
        }
    }, [competitorObject.adjudicator.lane, competitorObject.adjudicator.userID, event.adjudicators]);


    const switchAdjudicator = async (adjudicatorID) => {
        const response = await fetch(API_LINK + '/switch-adjudicator', {
            method: 'POST',
            body: JSON.stringify({ userID: competitorObject._id, eventID: event._id, adjudicatorID }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setSelectedAdjudicator(data.adjudicator)
            });
        }
    }

    return (
        selectedAdjudicator?.first_name &&
        <DropdownButton
            id="custom-dropdown"
            title={selectedAdjudicator.first_name.charAt(0) + '.' + selectedAdjudicator.last_name}
            onSelect={switchAdjudicator}
            variant="info"
        >
            {adjudicatorsDropdownList.map((adjudicator) => {
                return <Dropdown.Item eventKey={adjudicator.userID} key={adjudicator.userID}>
                    {adjudicator.first_name.charAt(0)}.{adjudicator.last_name}
                </Dropdown.Item>
            })}
        </DropdownButton>
    )
}

export default SwitchEventAdjudicator