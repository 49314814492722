import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Context } from '../../../lib/Context';
import InvisibleInput from './InvisibleInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Spinner from 'react-bootstrap/Spinner';
import '../../../lib/css/MapAdjudicator.css';

const MapAdjudicator = (props) => {
    const { API_LINK, events, setEvents, editAdjudicatorEventID } = useContext(Context);
    const { adjudicator, numberOfLanes, setAdjudicators, adjudicatorID } = props;
    const [laneNumber, setLaneNumber] = useState(0);
    const [laneArray, setLaneArray] = useState([]);
    const [firstname, setFirstname] = useState(adjudicator?.firstname || '');
    const [lastname, setLastname] = useState(adjudicator?.lastname || '');
    const [email, setEmail] = useState(adjudicator?.email || '');
    const [deletingAdjudicator, setDeletingAdjudicator] = useState(false);
    const [updatingAdjudicator, setUpdatingAdjudicator] = useState(false); // New state for spinner
    const eventID = editAdjudicatorEventID;

    useEffect(() => {
        let lanes = [];
        for (let i = 1; i < numberOfLanes + 1; i++) {
            lanes.push(i);
        }
        for (let i = 0; i < events.length; i++) {
            if (events[i]._id === eventID) {
                for (let j = 0; j < events[i].adjudicators.length; j++) {
                    if (adjudicatorID === events[i].adjudicators[j].userID) {
                        setLaneNumber(events[i].adjudicators[j].lane || 0);
                    }
                }
            }
        }
        setLaneArray(lanes);
    }, [adjudicatorID, eventID, events, numberOfLanes]);

    const updateLaneNumber = async (lane) => {
        setUpdatingAdjudicator(true); // Start spinner
        const response = await fetch(API_LINK + '/set-adjudicator-lanes', {
            method: 'POST',
            body: JSON.stringify({ eventID, adjudicatorID, laneNumber: parseInt(lane) }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });
        if (response.ok) {
            const data = await response.json();
            setEvents(data.events);
            setUpdatingAdjudicator(false);
        }
    };

    const handleSetLane = async (lane) => {
        setLaneNumber(lane);
        updateLaneNumber(lane);
    };

    const updateAdjudicator = async () => {
        setUpdatingAdjudicator(true); // Start spinner
        const response = await fetch(API_LINK + '/update-adjudicator', {
            method: 'POST',
            body: JSON.stringify({ userID: adjudicatorID, firstname, lastname, eventID, email }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        });
        if (response.ok) {
            const data = await response.json();
            setAdjudicators(data.adjudicators);
        }
        setUpdatingAdjudicator(false); // Stop spinner
    };

    const handleSetName = (event) => {
        const type = event.target.name;
        const value = event.target.value;
        switch (type) {
            case 'firstname':
                setFirstname(value);
                break;
            case 'lastname':
                setLastname(value);
                break;
            case 'email':
                setEmail(value);
                break;
            default:
                break;
        }
    };

    const removeAdjudicator = async () => {
        const userConfirmed = window.confirm('Do you want to proceed?');
        if (userConfirmed) {
            setDeletingAdjudicator(true);
            const response = await fetch(API_LINK + '/delete-adjudicator-from-event', {
                method: 'POST',
                body: JSON.stringify({ eventID, userID: adjudicator._id }),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setAdjudicators(data.adjudicators);
            }
            setDeletingAdjudicator(false);
        }
    };

    return (
        <>
            <td className="no-bg" style={{ color: 'white' }}>
                <InvisibleInput value={firstname} onInput={handleSetName}
                    onBlur={updateAdjudicator} name={'firstname'} type={"text"} />
            </td>
            <td className="no-bg" style={{ color: 'white' }}>
                <InvisibleInput value={lastname} onInput={handleSetName}
                    onBlur={updateAdjudicator} name={'lastname'} type={"text"} />
            </td>
            <td className="no-bg" style={{ color: 'white' }}>
                <InvisibleInput value={email} onInput={handleSetName}
                    onBlur={updateAdjudicator} name={'email'} type={"email"} />
            </td>
            <td className="no-bg" style={{ color: 'white' }}>
                <div style={{ width: '100%' }}>
                    <DropdownButton
                        id="smaller-dropdown"
                        title={laneNumber}
                        onSelect={handleSetLane}
                        variant="info"
                    >
                        {laneArray.map((laneNumber, index) => (
                            <Dropdown.Item key={'map-adjudicators-' + laneNumber} eventKey={index + 1}>{laneNumber}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </div>
            </td>
            <td style={{ textAlign: 'center', color: 'white' }} className="no-bg">
                {deletingAdjudicator || updatingAdjudicator ? (
                    <Spinner animation="border" size="sm" />
                ) : (
                    <FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faTrash} onClick={removeAdjudicator} />
                )}
            </td>
        </>
    );
};

export default MapAdjudicator;
