import Offcanvas from 'react-bootstrap/Offcanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { useContext } from 'react';
import { Context } from '../../lib/Context';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import '../../lib/css/Sidebar.css'
import '../../lib/css/DarkModal.css';
import logo from '../../lib/Images/logo.png'
import { Link } from 'react-router-dom';

const Sidebar = (props) => {
    const { setShowEditProfileModal, setViewPage, email, setShowAuth, logout } = useContext(Context);
    const { showSidebar, setShowSidebar } = props;

    const closeSidebar = () => {
        setShowSidebar(false);
    }

    return (
        <Offcanvas show={showSidebar} onHide={closeSidebar} style={{ backgroundImage: 'linear-gradient(#070039, #13066C)' }}>
            <Offcanvas.Header closeButton style={{ color: 'white' }}>
                <Link to="/" onClick={() => {
                    setViewPage('home-page')
                    setShowSidebar(false);
                }}>
                    <img src={logo} style={{ height: '40px', width: 'auto', marginTop: '7px' }} alt="Description" />
                </Link>
            </Offcanvas.Header>
            <Offcanvas.Body className="sidebar-body">

                <div className={`sidebar ${showSidebar ? 'open' : ''}`}>

                    {email &&
                        <>
                            <h1 style={{
                                fontFamily: 'Neon', color: '#26ffe2', fontSize: '2em', textAlign: 'center',
                                marginBottom: '30px'
                            }} className="glow-text">
                                LIVE TRAINING LEAGUE
                            </h1>

                            <Row>
                                <Col style={{ textAlign: 'center' }}>
                                    <button className="white-glow-button-menu " style={{
                                        fontSize: '1.7em', width: '100%', marginBottom: '20px', color: 'white'
                                    }} onClick={() => {
                                        setShowEditProfileModal(true);
                                        closeSidebar();
                                    }}
                                    >
                                        <span style={{ fontFamily: 'Neon' }}>MY PROFILE</span>
                                    </button>
                                    <Link to="/events" >
                                        <button className="white-glow-button-menu" style={{
                                            fontSize: '1.7em', width: '100%', marginBottom: '20px', color: 'white'
                                        }} onClick={() => {
                                            setViewPage('events-page');
                                            closeSidebar();
                                        }}
                                        >
                                            <span style={{ fontFamily: 'Neon' }}>EVENTS</span>
                                        </button>
                                    </Link>
                                </Col>
                            </Row>
                        </>
                    }
                </div>
                <Row className="logout-row">
                    <Col>
                        {email ?
                            <button className="white-glow-button-menu" style={{
                                fontSize: '1.7em', width: '100%', color: 'white'
                            }} onClick={() => {
                                logout();
                                closeSidebar();
                            }}
                            >
                                <span style={{ fontFamily: 'Neon' }}>LOG OUT
                                    <span style={{ fontSize: '0.55em', marginLeft: '20px' }}>
                                        <FontAwesomeIcon icon={faRightFromBracket} />
                                    </span>
                                </span>
                            </button>
                            :
                            <button className="white-glow-button-menu" style={{
                                fontSize: '1.7em', width: '100%', color: 'white'
                            }} onClick={() => {
                                setShowAuth(true);
                                closeSidebar();
                            }}
                            >
                                <span style={{ fontFamily: 'Neon' }}>LOG IN
                                    <span style={{ fontSize: '0.55em', marginLeft: '20px' }}>
                                        <FontAwesomeIcon icon={faRightToBracket} />
                                    </span>
                                </span>
                            </button>
                        }
                    </Col>
                </Row>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default Sidebar