import { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Context } from '../../lib/Context';
import EventInfo from './ModalElements/EventInfo';
import AddExercises from './ModalElements/AddExercises';
import AddAgeGroups from './ModalElements/AddAgeGroups';
import '../../lib/css/AddEventModal.css';
import '../../lib/css/DarkModal.css';

const AddEventModal = () => {
    const { showEventModal, setShowEventModal, API_LINK, setEvents, editEventID, setEditEventID, events,
        setShowAlert, setAlertObject
    } = useContext(Context);
    const [address, setAddress] = useState('');
    const [postcode, setPostcode] = useState('');
    const [startDateLive, setStartDateLive] = useState(new Date());
    const [endDateLive, setEndDateLive] = useState(new Date());
    const [startDateVirtual, setStartDateVirtual] = useState(new Date());
    const [endDateVirtual, setEndDateVirtual] = useState(new Date());
    const [eventType, setEventType] = useState('Hybrid');
    const [eventName, setEventName] = useState('');
    const [step, setStep] = useState(1);
    const [exercises, setExercises] = useState([]);
    const [ageGroups, setAgeGroups] = useState([
        { low_age: 12, high_age: 13 },
        { low_age: 14, high_age: 15 },
        { low_age: 16, high_age: 18 },
        { low_age: 19, high_age: 29 },
        { low_age: 30, high_age: 39 },
        { low_age: 40, high_age: 49 },
        { low_age: 50, high_age: 100 }
    ]);

    const [seasonNumber, setSeasonNumber] = useState(0);
    const [virtualFee, setVirtualFee] = useState(0);
    const [liveFee, setLiveFee] = useState(0);
    const [lockInEvent, setLockInEvent] = useState(false); // Stops past events from being changed i.e. eventType

    useEffect(() => {
        let existingEvent = false;
        for (let i = 0; i < events?.length; i++) {
            if (events[i]._id === editEventID) {
                setAddress(events[i].address);
                setPostcode(events[i].postcode);
                let type = events[i].eventType;
                setEventType(type);
                setStartDateLive(events[i].start_time_live)
                setEndDateLive(events[i].end_time_live);
                setStartDateVirtual(events[i].start_time_virtual);
                setEndDateVirtual(events[i].end_time_virtual);
                setLiveFee(events[i].liveFee || 0)
                setVirtualFee(events[i].virtualFee || 0)
                setAgeGroups(events[i].age_groups);
                setExercises(events[i].exercises);
                setSeasonNumber(events[i].season_number ? events[i].season_number : events.length + 1);
                setEventName(!events[i].event_name ? '' : events[i].event_name)
                existingEvent = true;
            }
        }
        if (!existingEvent) {
            // Get number of events that are type 'Hybrid'
            let seasonCount = 0;
            for (let i = 0; i < events?.length; i++) {
                if (events[i].eventType === 'Hybrid') {
                    seasonCount++
                }
            }
            setSeasonNumber(seasonCount + 1);
        } else {
            setLockInEvent(true);
        }
    }, [events, editEventID]);

    const handleInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        switch (name) {
            case 'event_name':
                setEventName(value);
                break;
            case 'address':
                setAddress(value);
                break;
            case 'postcode':
                setPostcode(value);
                break;
            case 'season_number':
                setSeasonNumber(value);
                break;
            case 'live-fee':
                setLiveFee(value);
                break;
            case 'virtual-fee':
                setVirtualFee(value);
                break;
            default:
                break;
        }
    }

    const addNewAgeGroup = () => {
        let value = ageGroups[ageGroups?.length - 1]?.high_age + 1 || 0;
        let ageGroupsList = [...ageGroups, { low_age: value, high_age: 0 }]
        setAgeGroups(ageGroupsList);
    }

    const removeAgeGroup = (index) => {
        let ageGroupsList = [...ageGroups];
        ageGroupsList.splice(index, 1);
        setAgeGroups(ageGroupsList);
    }

    const handleClose = () => {
        setShowEventModal(false);
        setTimeout(() => {
            setAddress('');
            setPostcode('');
            setStartDateLive(new Date());
            setEndDateLive(new Date());
            setStartDateVirtual(new Date());
            setEndDateVirtual(new Date());
            setStep(1);
            setEditEventID('');
            setSeasonNumber('')
            setLockInEvent(false);
            setEventType('Hybrid');
        }, "200");
    };

    const stepForward = () => {
        setStep(step + 1)
    }

    const stepBack = () => {
        setStep(step - 1);
    }

    const checkAgeGroups = (ageGroups) => {
        // First, sort the ageGroups by the low_age value
        ageGroups.sort((a, b) => a.low_age - b.low_age);

        // Check if the groups cover all ages, don't overlap, and each group's low_age is less than high_age
        for (let i = 0; i < ageGroups?.length; i++) {
            const currentGroup = ageGroups[i];

            // Check if low_age is less than high_age for each group
            if (currentGroup.low_age >= currentGroup.high_age) {
                return { status: false, message: `Invalid age group at index ${i}: low_age (${currentGroup.low_age}) must be less than high_age (${currentGroup.high_age})` };
            }

            // Ensure we're not checking for gaps/overlaps on the last group
            if (i < ageGroups?.length - 1) {
                const nextGroup = ageGroups[i + 1];

                // Ensure there is no overlap (the current group's high_age should be less than the next group's low_age)
                if (currentGroup.high_age >= nextGroup.low_age) {
                    return { status: false, message: `Overlap found between ${currentGroup.high_age} and ${nextGroup.low_age}` };
                }

                // Ensure there are no gaps (the next group's low_age should be 1 more than current group's high_age)
                if (currentGroup.high_age + 1 < nextGroup.low_age) {
                    return { status: false, message: `Gap found between ${currentGroup.high_age} and ${nextGroup.low_age}` };
                }
            }
        }

        return { status: true, message: '' };
    };


    const submitEvent = async () => {
        // Perform a check to ensure the ages are good i.e. no 0 in last element, no gaps in ages.
        // Show error message if this is the case.
        let check = checkAgeGroups(ageGroups);
        if (!check.status) {
            setShowAlert(true);
            let alertMessage = {
                variant: 'danger',
                text: check.message
            }
            setAlertObject(alertMessage);
        } else {
            // This is where the call is made to the backend to save the event
            const response = await fetch(API_LINK + '/save-event', {
                method: 'POST',
                body: JSON.stringify({
                    eventID: editEventID, address, postcode, start_time_live: startDateLive, end_time_live: endDateLive,
                    start_time_virtual: startDateVirtual, end_time_virtual: endDateVirtual,
                    exercises, age_groups: ageGroups, seasonNumber, eventType, liveFee, virtualFee,
                    eventName
                }),
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include'
            })
            if (response.ok) {
                response.json().then(async data => {
                    if (data.error) {
                        console.log('There was an error: ' + data.error);
                    } else {
                        setEvents(data.events);
                        setShowEventModal(false);
                        setAddress('');
                        setPostcode('');
                        setStartDateLive(new Date());
                        setEndDateLive(new Date());
                        setStartDateVirtual(new Date());
                        setEndDateVirtual(new Date());
                        setTimeout(() => {
                            setStep(1);
                            setEditEventID('');
                        }, "200");
                    }
                });
            }
        }

    }

    const addExercise = () => {
        let newExercises = [...exercises];
        let index = newExercises.length;
        newExercises.push({ name: 'Exercise Name', type: 'Weights', reps: 0, distance: 0, index });
        setExercises(newExercises);
    }

    const removeExercise = (index) => {
        let newExercises = [...exercises];
        newExercises.splice(index, 1)
        setExercises(newExercises);
    }

    const switchType = (props) => {
        const { index, value } = props;
        let newExercises = [...exercises];
        newExercises[parseInt(index)].type = value;
        if (value === 'Time') {
            newExercises[parseInt(index)].name = 'Time'
        }
        setExercises(newExercises);
    }

    return (
        <Modal show={showEventModal} onHide={handleClose} className="modal-dark" size={(step === 1 || step === 3) ? '' : 'lg'}>
            <Modal.Header closeButton>
                <Modal.Title>{
                    step === 1 ? 'Create New Event' :
                        step === 2 ? 'Add Exercises' :
                            'Add Age Groups'
                }</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {step === 1 &&
                    <EventInfo
                        address={address} startDateLive={startDateLive} endDateLive={endDateLive} handleInput={handleInput}
                        setStartDateLive={setStartDateLive} startDateVirtual={startDateVirtual}
                        setEndDateLive={setEndDateLive} postcode={postcode} seasonNumber={seasonNumber}
                        setStartDateVirtual={setStartDateVirtual} endDateVirtual={endDateVirtual}
                        setEndDateVirtual={setEndDateVirtual} eventType={eventType} setEventType={setEventType}
                        virtualFee={virtualFee} liveFee={liveFee} lockInEvent={lockInEvent}
                        eventName={eventName}
                    />
                }
                {step === 2 &&
                    <AddExercises
                        exercises={exercises} addExercise={addExercise} removeExercise={removeExercise}
                        switchType={switchType} setExercises={setExercises}
                    />
                }
                {step === 3 &&
                    <AddAgeGroups addNewAgeGroup={addNewAgeGroup} ageGroups={ageGroups} removeAgeGroup={removeAgeGroup}
                        setAgeGroups={setAgeGroups} />
                }
            </Modal.Body>
            <Modal.Footer>
                <div style={{ width: '100%' }}>
                    {(step === 2 || step === 3) &&
                        <button className="grey-glow-button float-left" style={{ float: 'left' }} onClick={stepBack}>
                            Back
                        </button>
                    }
                    {(step === 1 || step === 2) &&
                        <button className="green-glow-button float-right" onClick={stepForward}>
                            Next
                        </button>
                    }
                    {step === 3 &&
                        <button className="green-glow-button float-right" onClick={submitEvent}>
                            {editEventID ? 'Update Event' : 'Create Event'}
                        </button>
                    }
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default AddEventModal;