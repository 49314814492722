import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Context } from '../../../../lib/Context';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import VirtualElement from './VirtualElement';
import LiveElement from './LiveElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import ModalHeader from '../ModalHeader';
import '../../../../lib/css/MapEvent.css'

const MapEventBlocks = (props) => {
    // eslint-disable-next-line
    let { event, index, eventID, isLive, eventType, getCompetitors } = props;
    // eslint-disable-next-line
    const { API_LINK, setShowAddCompetitorModal, setAddCompetitorEventID, setShowEventModal, setEditEventID, setViewEventID, events,
        setMapCompetitorsEventID, setShowMapCompetitorsModal, setShowAddAdjudicatorModal, setAddAdjudicatorEventID,
        // eslint-disable-next-line
        setShowEditAdjudicatorModal, setEditAdjudicatorEventID, type, setCollectWeightsEventID, setShowCollectWeightsModal,
        // eslint-disable-next-line
        setShowAlert, setAlertObject, checkIsAdjudicator, setEvents, setEvent, setMapCompetitorsEventType, setIsLiveEvent,
        usersID
    } = useContext(Context);

    const [isVirtualCompetitor, setIsVirtualCompetitor] = useState(false);
    const [userQualified, setUserQualified] = useState(false);
    const [eventName, setEventName] = useState('');

    // Need to see if user is competitor in live event
    useEffect(() => {
        let eventID = event._id;
        for (let i = 0; i < events.length; i++) {
            if (events[i]._id === eventID) {
                setEventName(!events[i].event_name ? (
                    events[i].eventType === 'Live' ? 'LIVE EVENT'
                        :
                        events[i].eventType === 'Virtual' && 'VIRTUAL EVENT'
                ) : events[i].event_name);
                for (let j = 0; j < events[i].virtual_competitors.length; j++) {
                    if (usersID === events[i].virtual_competitors[j].userID) {
                        setIsVirtualCompetitor(true);
                    }
                }
            }
        }
    }, [events, usersID, event._id])

    // eslint-disable-next-line
    const handleSwitchChange = async () => {
        // setIsLive(!isLive);  // Toggle switch state
        const response = await fetch(API_LINK + '/set-event-live-status', {
            method: 'POST',
            body: JSON.stringify({ eventID, status: !isLive }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
        if (response.ok) {
            response.json().then(async data => {
                setEvents(data.events);
            });
        }
    };

    const handleShowAddCompetitorsModal = (eventID) => {
        setAddCompetitorEventID(eventID);
        setShowAddCompetitorModal(true);
    }

    // eslint-disable-next-line
    const handleShowEventModal = (eventID) => {
        setEditEventID(eventID);
        setShowEventModal(true);
    }

    const handleViewEvent = (eventID) => {
        // Get events and then set event (so the most recent scores and weights are present).
        fetch(API_LINK + '/get-events', {
            credentials: 'include',
            method: 'GET'
        }).then(response => {
            response.json().then(data => {
                const event = data.events.find(event => event._id === eventID);
                setEvent(event);
                const eventValid = event && !event.adjudicators.some(adjudicator => adjudicator.lane === 0);
                let adjudicatorsValid = true;
                // Checks to see if any competitors don't have an adjudicator assigned to them
                // Need to only check the users that are relevant to the competition i.e ages.
                for (let i = 0; i < event.age_groups.length; i++) {
                    for (let j = 0; j < event.competitors.length; j++) {
                        if (event.competitors[j].age >= event.age_groups[i].low_age &&
                            event.competitors[j].age <= event.age_groups[i].high_age
                        ) {
                            if (!event.competitors[j].adjudicator) {
                                adjudicatorsValid = false;
                            }
                        }
                    }
                }
                // TODO: Check to see if the user is an adjudicator in this event. If so then show ViewAdjudicatorEvent.
                if (eventValid && adjudicatorsValid) {
                    setViewEventID(eventID);
                } else {
                    setShowAlert(true);
                    let alertMessage = {
                        variant: 'danger',
                        text: type === 'admin' ?
                            'All adjudicators need to be assigned to a lane before you can view the event.'
                            :
                            'This event is not ready to be viewed yet.'
                    }
                    setAlertObject(alertMessage);
                }
            })
        })

    }

    const handleShowMapCompetitorsModal = (props) => {
        let { eventID, type } = props;
        setShowMapCompetitorsModal(true);
        setMapCompetitorsEventID(eventID);
        if (type === 'Live') {
            setIsLiveEvent(true);
        } else {
            setIsLiveEvent(false);
        }
    }

    // eslint-disable-next-line
    const formatDate = useCallback((dateString) => {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    }, []);

    // eslint-disable-next-line
    const formatTime = useCallback((dateString) => {
        const date = new Date(dateString);
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }, [])

    const handleShowAddAdjudicatorModal = (eventID) => {
        setShowAddAdjudicatorModal(true);
        setAddAdjudicatorEventID(eventID);
    }

    const handleShowEditAdjudicatorsModal = (props) => {
        const { eventID } = props;
        setShowEditAdjudicatorModal(true);
        setEditAdjudicatorEventID(eventID);
    }

    // eslint-disable-next-line
    const handleShowCollectUserWeightsModal = (event) => {
        let eventID = event._id;
        let isLive = event.isLive;
        if (!isLive) {
            setCollectWeightsEventID(eventID);
            setShowCollectWeightsModal(true);
        } else {
            let alertMessage = {
                variant: 'danger',
                text: 'This event is LIVE and as such you\'re not allowed to update your weights.'
            }
            setAlertObject(alertMessage);
            setShowAlert(true);
        }
    }

    return (
        <div style={{
            backgroundColor: '#231c59', border: '1px solid #231c59', borderRadius: '20px', marginTop: '10px',
            marginBottom: '50px'
        }}>
            <Row style={{ padding: '20px' }}>
                <Col xs={12} md={4}>
                    {type === 'admin' &&
                        <button className="edit-glow-button"

                            onClick={() => { handleShowEventModal(event._id) }}
                        >
                            <FontAwesomeIcon icon={faPencil} />
                        </button>
                    }
                </Col>
                <Col xs={12} md={4}>
                    <div className="glow-text" style={{
                        fontWeight: '100',
                        paddingTop: '20px', textAlign: 'center', color: '#26ffe1', lineHeight: '1.3em',
                        fontSize: '2.5em', fontFamily: 'Neon'
                    }}>
                        {event.eventType === 'Hybrid' &&
                            `SEASON #${event.season_number}:`} {event.eventType === 'Live' ?
                                eventName.toUpperCase() : event.eventType === 'Virtual' && eventName.toUpperCase()} <br />
                        {
                            <div style={{ marginTop: '-15px', fontSize: '0.6em', fontFamily: 'Urbanist' }}>
                                {event.eventType === 'Virtual' ? 'Online' : `${event.address}, ${event.postcode}`}
                            </div>
                        }
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    {type === 'admin' &&
                        <Form>
                            <Form.Check style={{ float: 'right' }} // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label={isLive ? 'Live' : 'Off'}
                                checked={isLive}  // Control the switch state
                                onChange={handleSwitchChange}  // Handle state change
                                name={event._id}
                            />
                        </Form>
                    }
                </Col>
            </Row>
            <Row style={{ textAlign: 'center', padding: '5px' }} >
                <Col xs={4} style={{ marginTop: '20px' }}>
                    <h5 style={{ color: '#26ffe2' }}>Lifts</h5>
                    {event.exercises.map((exercise, index) => (
                        exercise.type === "Weights" &&
                        <div key={'exercise-list-' + index + '-' + event._id} style={{ lineHeight: '1.2em' }}>
                            {exercise.name}
                        </div>
                    ))}
                </Col>
                <Col xs={4} style={{ marginTop: '20px' }}>
                    <h5 style={{ color: '#26ffe2' }}>Cardio</h5>
                    {event.exercises.map((exercise, index) => (
                        exercise.type === "CV" &&
                        <div key={'exercise-list-' + index + '-' + event._id} style={{ lineHeight: '1.2em' }}>
                            {exercise.name}
                        </div>
                    ))}
                </Col>
                <Col xs={4} style={{ marginTop: '20px' }}>
                    <h5 style={{ color: '#26ffe2' }}>Age Groups</h5>
                    {event.age_groups.map((age_group, index) => {
                        return <div key={'age-groups-' + index + '-' + event._id} style={{ lineHeight: '1.2em' }}>
                            {age_group.low_age}{age_group.high_age !== 100 ? ' to ' + age_group.high_age : '+'}
                        </div>
                    })}
                </Col>
            </Row>
            {event.eventType === 'Hybrid' &&
                <>
                    {(userQualified || type === 'admin') && <ModalHeader text='VIRTUAL' fontSize={'2em'} />}
                    <Row style={{ textAlign: 'center' }}>
                        <Col xs={12}>
                            <VirtualElement event={event} handleViewEvent={handleViewEvent} handleShowMapCompetitorsModal={handleShowMapCompetitorsModal}
                                getCompetitors={getCompetitors} />
                        </Col>
                    </Row>
                    <div className="glow-text"
                        style={{
                            fontFamily: 'Neon', textAlign: 'center', marginTop: '10px', color: '#26ffe2'
                        }}>
                        {(userQualified || type === 'admin') && <ModalHeader text='LIVE' fontSize={'2em'} />}
                    </div>
                    <Row>
                        <Col>
                            {((isVirtualCompetitor && userQualified) || type === 'admin') &&
                                <Col>
                                    <LiveElement handleShowMapCompetitorsModal={handleShowMapCompetitorsModal} event={event}
                                        handleShowAddCompetitorsModal={handleShowAddCompetitorsModal}
                                        handleShowAddAdjudicatorModal={handleShowAddAdjudicatorModal}
                                        handleShowEditAdjudicatorsModal={handleShowEditAdjudicatorsModal}
                                        handleViewEvent={handleViewEvent} userQualified={userQualified}
                                        setUserQualified={setUserQualified} />
                                </Col>
                            }
                        </Col>
                    </Row>
                </>
            }

            {event.eventType === 'Live' &&
                <Row style={{ marginTop: '20px', textAlign: 'center', paddingBottom: '20px' }}>
                    <Col>
                        <LiveElement handleShowMapCompetitorsModal={handleShowMapCompetitorsModal} event={event}
                            handleShowAddCompetitorsModal={handleShowAddCompetitorsModal}
                            handleShowAddAdjudicatorModal={handleShowAddAdjudicatorModal}
                            handleShowEditAdjudicatorsModal={handleShowEditAdjudicatorsModal}
                            handleViewEvent={handleViewEvent} userQualified={userQualified}
                            setUserQualified={setUserQualified} />
                    </Col>
                </Row>
            }
            {event.eventType === 'Virtual' &&
                <Row style={{ marginTop: '10px', textAlign: 'center' }}>
                    <Col>
                        <VirtualElement event={event} handleViewEvent={handleViewEvent}
                            handleShowMapCompetitorsModal={handleShowMapCompetitorsModal}
                            getCompetitors={getCompetitors}
                        />
                    </Col>
                </Row>
            }
        </div>
    )
}

export default MapEventBlocks