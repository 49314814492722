import React, { useContext, useEffect } from 'react';
import { motion as m } from 'framer-motion'
import { useLocation } from 'react-router-dom';
import { Context } from '../../lib/Context';
import PromoVideo from '../Pages/PageElements/PromoVideo'
import '../../lib/css/HomePage.css';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket } from '@fortawesome/free-solid-svg-icons';

const HomePage = () => {
    const { viewPage, setViewPage, email, setShowAuth } = useContext(Context);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/events' && !email) {
            window.location.href = "http://localhost:3000"
        }
        if (location.pathname === '*') {
            setViewPage('home');
        }
    }, [location.pathname, setViewPage, email]);

    return (
        viewPage === 'home-page' &&
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.75, ease: 'easeOut' }}
            className="parallax-bg"
            style={{ minHeight: '100vh' }}
        >
            <Container style={{ marginTop: '0px' }}>
                <Row>
                    <Col lg={7} >
                        <h1 className='glow-text' style={{
                            fontFamily: 'Neon', color: '#26ffe2', fontSize: '3.8em', lineHeight: '0.7em',
                            textTransform: 'uppercase', textAlign: 'center', marginTop: '30px'
                        }}>
                            The Hybrid LIVE Gym Training League
                        </h1>
                        <p style={{ color: 'white', fontSize: '1.2em', marginTop: '30px', textAlign: 'center' }}>
                            X-TRON Live is forging a new breed of hybrid warriors - ready to take on anything life throws their way.
                            Become part of an elite uprising, a team that dominates in cardio, strength and endurance. Crush your
                            competition in the virtual gym arena and earn your place in the LIVE final showdown.

                        </p>
                        <button className="green-glow-button-large" style={{ marginTop: '20px', marginBottom: '30px' }}
                            onClick={() => { setShowAuth(true) }}>
                            <span style={{ marginRight: '10px', fontSize: '1.3em' }}>
                                <strong>REGISTER FOR YOUR SPOT NOW</strong>
                            </span>
                            <FontAwesomeIcon icon={faRocket} />
                        </button>
                    </Col>
                    <Col lg={5}>
                        <PromoVideo />
                    </Col>
                </Row>
            </Container>
        </m.div>
    )
}

export default HomePage